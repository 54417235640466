import loadable from '@loadable/component';

// Imports => Views
const AcHome = loadable(() => import('@views/ac-home/ac-home'));
const AcAccountSelect = loadable(() =>
	import('@views/ac-account-select/ac-account-select')
);
const AcAchievement = loadable(() =>
	import('@views/ac-achievement/ac-achievement')
);
const AcActionDetail = loadable(() =>
	import('@views/ac-action-detail/ac-action-detail')
);
const AcActionFinished = loadable(() =>
	import('@views/ac-action-finished/ac-action-finished')
);
const AcActionParticipants = loadable(() =>
	import('@views/ac-action-participants/ac-action-participants')
);
const AcCanvas = loadable(() => import('@views/ac-canvas/ac-canvas'));
const AcConversation = loadable(() =>
	import('@views/ac-conversation/ac-conversation')
);
const AcConversationFinished = loadable(() =>
	import('@views/ac-conversation-finished/ac-conversation-finished')
);
const AcForgotPassword = loadable(() =>
	import('@views/ac-forgot-password/ac-forgot-password')
);
const AcFullscreenComment = loadable(() =>
	import('@views/ac-fullscreen-comment/ac-fullscreen-comment')
);
const AcHangInThere = loadable(() =>
	import('@views/ac-hang-in-there/ac-hang-in-there')
);
const AcLogin = loadable(() => import('@views/ac-login/ac-login'));
const AcPearl = loadable(() => import('@views/ac-pearl/ac-pearl'));
const AcResetPassword = loadable(() =>
	import('@views/ac-reset-password/ac-reset-password')
);
const AcSettings = loadable(() => import('@views/ac-settings/ac-settings'));
const AcSplashScreen = loadable(() =>
	import('@views/ac-splash-screen/ac-splash-screen')
);
const AcScoreFAQ = loadable(() => import('@views/ac-score-faq/ac-score-faq'));
const AcTipsAndTricks = loadable(() =>
	import('@views/ac-tips-and-tricks/ac-tips-and-tricks')
);

export {
	AcAccountSelect,
	AcAchievement,
	AcActionDetail,
	AcActionFinished,
	AcActionParticipants,
	AcCanvas,
	AcConversation,
	AcConversationFinished,
	AcForgotPassword,
	AcFullscreenComment,
	AcHangInThere,
	AcHome,
	AcLogin,
	AcPearl,
	AcResetPassword,
	AcSettings,
	AcSplashScreen,
	AcScoreFAQ,
	AcTipsAndTricks,
};
